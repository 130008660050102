.login input[type=number] {
    display: block;
    color: #858585;
    height: 36px;
    width: 100%;
    padding: 0 21px;
    line-height: 100%;
    border: 1px solid #e0e0e0;
    letter-spacing: .8px;
}

.login .login__form label {
    display: block;
}

.login .MuiFormControl-root{
    width: 100%;
}

.login .MuiInputBase-root input[type="text"]{
    font-size: 12px;
    border: unset;
    height: unset;
    padding: 15px 25px !important;
}


.login .MuiInputLabel-outlined{
    font-size: 12px;
}

.login .MuiInputLabel-shrink{
    right: 16px;
}

.login .MuiInputBase-root fieldset {
    right: 0px;
    text-align: unset;
}

.login__info p.Mui-error{
    margin: 3px 10px 0px;
}