.main-dashboard .card {
    border-radius: 0px 0px 0px 0px;
    font-weight: 500;

    line-height: 0.05%;
    letter-spacing: 0.09px;
    text-align: center;

}

.main-dashboard .card:hover {
    cursor: pointer;
    box-shadow: 0 0 0 1px;

}

.main-dashboard .icon-large>svg {
    font-size: 4rem;
}

.main-dashboard .card-text>.card-desc {
    color: black;
    line-height: normal;
    word-wrap: break-word;
    height: 2em;
}

.main-dashboard .card-text>.label {
    font-size: 42px;
    font-weight: bold;
    text-align: center;
    color: #298cca;
}

.loadBoxSection{
    position: absolute;
    left: calc(50% - 20px);
    bottom: 41px;
    background: #fff;
}


@media screen and (max-width: 900px) {
    .main-dashboard .card {
        padding: 25px 7px 19px;
    }


    .main-dashboard .card-text>.card-desc {
        color: black;
        height: unset;
        line-height: unset;
    }
}