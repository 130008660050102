@import "../variables";
@import "../mixins";

.instructor-reports, .participant-reports {
  padding: 19px 30px 97px;
}

.instructor-reports {
  &__top-block {
    display: flex;
    align-items: flex-start;
    gap: 15px;

    .event-progress-block {
      flex-basis: calc(50% - 7.5px);

      &.only-progress-title {
        flex-basis: auto;
        margin-right: auto;
      }
    }
  }

  &__filtration-dropdown {
    flex-basis: calc(50% - 7.5px);

    &:hover {
      box-shadow: 0 0 0 1px #bbb;
    }

    label {
      text-indent: -9999px;
      position: absolute;
    }

    input {
      height: 63px;
      padding: 0 23px;
      font-size: 19px;
      border: none;
      cursor: pointer;
      color: $dark-color;
      letter-spacing: 0.1px;
      @include placeholder {
        color: $dark-color;
        opacity: 1;
      }
    }

    .dropdown-content {
      padding: 10px 32px 45px;

      li:not(:last-child) {
        margin: 0 0 23px;
      }
    }
  }

  &__list {
    display: flex;
    gap: 30px;

    ul {
      flex-basis: calc(33.33% - 10px);
    }
  }
}

.reports {
  &-info {
    margin: 0 0 39px;

    &__labels, &__row {
      display: flex;
      gap: 9px;
      background: $light-color;
      box-sizing: border-box;
    }

    &__labels {
      padding: 16px 12px 12px;
      border-bottom: 1px solid #d0d0d0;
    }

    &__row {
      align-items: center;

      &:not(.add-report-wrapper) {
        padding: 19px 12px;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #f0f0f0;
      }

      .collapse-content-btn {
        transform: rotateZ(180deg);
      }
    }

    &__date, &__starting-hour, &__hours-count, &__ending-hour, &__status {
      max-width: 129px;
    }

    &__reporting-confirmation {
      max-width: 123px;
      margin: 0 auto 0 0;

      .btn {
        width: 100%;
        border: 1px solid $default-color;
        color: $default-color;
        background: none;
        min-height: 28px;

        &:hover {
          background: $default-color;
          color: $dark-color;
        }
      }
    }

    &__comment__tooltip {
      display: none;
      position: absolute;
      top: 191%;
      left: 0;
      z-index: 3;
      width: 100%;
      background: #494949;
      padding: 15px 13px;
      color: $light-color;
      line-height: 1.65;
      box-sizing: border-box;

      &::before {
        content: "";
        width: 0;
        height: 0;
        border-width: 0 15px 21px;
        border-color: transparent transparent #494949;
        border-style: solid;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &-status {
    color: #a3a3a3;
  }

  &-comment {
    &.large-comment {
      @include cutText {
        -webkit-line-clamp: 1;
      }
    }

    &-wrapper {
      position: relative;

      &:hover .reports-info__comment__tooltip {
        display: block;
      }
    }
  }

  &-title {
    display: flex;
    align-items: center;
    gap: 23px;

    .title-with-line {
      flex-grow: 1;
    }
  }
}

.add-report-btn {
  min-height: 62px;
  max-width: 132px;
  width: 100%;
  padding: 0 15px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  background: $default-color;

  &:hover {
    background: $hover-default-color;
  }

  &::before {
    content: "+";
    font-family: $primary-font-family;
    color: $dark-color;
    font-size: 17px;
    line-height: 1;
    font-weight: 700;
    display: inline-block;
    margin: 0 0 0 5px;
  }
}

.mobile-report-preview {
  display: flex;
  gap: 10px;
  align-items: center;
  background: $light-color;
  padding: 14px 22px 14px 12px;
  margin: 0 0 10px;
  position: relative;

  .collapse-content-btn {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  p {
    margin: 0;

    &:not(:last-of-type) {
      padding: 0 0 0 10px;
      position: relative;

      &::after {
        content: '';
        width: 1px;
        height: 110%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background: #e6e6e6;
      }
    }
  }
}

.report-author-info {
  &__content {
    background: $light-color;
    padding: 24px 12px 24px 17px;
    display: flex;
    gap: 24px;
    align-items: center;
    font-size: 16px;
    letter-spacing: 0.5px;

    p {
      margin: 0;

      a {
        text-decoration: none;
      }
    }
  }

  &__name {
    flex-grow: 1;

    .report-author-info__label {
      display: none;
    }
  }

  &__email {
    padding: 2px 0 0 25px;
    position: relative;

    &::after {
      content: "";
      width: 1px;
      height: 155%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background: #cdcdcd;
    }

    a {
      color: $default-color;

      &:hover {
        color: $default-color;
        text-decoration: underline;
      }
    }
  }

  &__tel a {
    color: $dark-color;
    pointer-events: none;
    font-size: 18px;
  }

  &__label {
    color: $darker-grey;
  }
}

.new-report-popup {
  .popup-content {
    background: $light-color;
    padding: 14px 19px 86px;
    box-sizing: border-box;
    max-width: 589px;
    width: 100%;

    &__wrapper {
      max-width: 429px;
      margin: 0 auto;
    }
  }

  .popup-form {
    display: flex;
    flex-wrap: wrap;
    column-gap: 15px;
  }

  h2 {
    font-size: 20px;
    margin: 0 0 53px;
  }

  label {
    display: block;
    margin: 0 0 3px;
  }

  input {
    &:not(.btn) {
      color: $darker-grey;
      border: 1px solid $light-grey;
      min-height: 32px;
      text-align: center;
      background: $light-color;
      box-shadow: none;
      border-radius: 0;
      @include placeholder {
        color: $darker-grey;
        opacity: 1;
      }
    }

    &[type="text"] {
      width: 112px;
    }
  }

  textarea {
    width: 100%;
    height: 106px;
  }

  .field-wrapper {
    margin: 0 0 25px;

    &:first-of-type input {
      width: 150px;
      margin: 0 0 0 25px;
    }

    &:last-of-type {
      flex-basis: 100%;
      margin-bottom: 20px;
    }

    &.error input {
      border-color: $error-color;
      color: $error-color;
      @include placeholder {
        color: $error-color;
        opacity: 0;
      }
    }
  }

  .btn {
    margin: 0 auto 0 0;
  }
}

.participant-reports {
  .reports-info__comment {
    max-width: 261px;
  }

  .reports-info__row {
    min-height: 67px;
  }
}