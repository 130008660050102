.banner-card{
    max-width: 353px;
}

@media screen and (max-width: 768px) {
    .banner-card{
        max-width: 100%;
        padding: 0 9px;
        margin-bottom: 50px;
    }
}