@import "../variables";

.text-page {
  padding: 30px 30px 56px;
  background-color: $light-color;
  line-height: 1.625;
  font-size: 16px;
  border-bottom: 1px solid $light-grey;

  .content-container {
    max-width: 843px;
    letter-spacing: 0.3px;

    > *:last-child {
      margin-bottom: 0;
    }
  }

  h1 {
    line-height: normal;
    padding: 0 0 19px;
    margin: 0 0 18px;
    position: relative;

    &::after {
      content: '';
      width: 59px;
      height: 1px;
      background-color: $light-grey;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  &__date {
    letter-spacing: 0.25px;
    color: #909090;
    margin: 0 0 32px;
    display: inline-block;

    & + p {
      font-size: 18px;
    }
  }

  h2, h3 {
    font-size: 17px;
    line-height: 1.5;
    margin: 0;
  }

  p, ul {
    letter-spacing: 0.25px;
    margin: 0 0 30px;
  }

  ul {
    padding: 0 16px 0 0;
  }

  a {
    color: $default-color;
    text-decoration: underline;
  }

  .tel {
    font-size: 18px;
  }
}
