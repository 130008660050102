@import "variables";
@import "mixins";

@media screen and (max-width: 1240px) {
  .event-info {
    font-size: 14px;

    &__name {
      max-width: 385px;
    }
  }

  .cancel-event-btn {
    font-size: 14px;
  }
}

@media screen and (max-width: 1150px) {
  .card-wrapper {
    justify-content: space-between;
  }

  .event-info__name {
    max-width: 267px;
  }

  .participant-reports .reports-info__comment {
    max-width: 160px;
  }
}

@media screen and (max-width: 1060px) {
  .participant-reports .reports-info__comment {
    max-width: 129px;
  }
}

@media screen and (max-width: 1024px) {
  .event-info {
    &__date {
      max-width: 210px;
    }

    &__hours {
      max-width: 110px;
    }

    &__practice-hours {
      max-width: 94px;
    }
  }

  .reports-info {
    &__date, &__starting-hour, &__hours-count, &__ending-hour, &__status {
      max-width: 111px;
    }
  }

  .add-report-btn {
    min-height: 48px;
  }

  .participant-reports .reports-info__comment {
    max-width: 168px;
  }

  .account-nav ul {
    gap: 25px;
  }
}

@media screen and (max-width: 992px) {
  #main {
    margin: 49px 0 0;
  }

  .volunteering-title {
    border-top: 1px solid $light-grey;
  }

  .reports-info {
    &__date, &__starting-hour, &__hours-count, &__ending-hour, &__status, &__reporting-confirmation {
      max-width: 96px;
    }
  }

  .participant-reports .reports-info__comment {
    max-width: 222px;
  }

  .record-form {
    &__section {
      padding: 45px 15px 37px;
    }

    &__wrapper {
      padding: 35px;
    }
  }
  .project-info__image {
    flex-basis: 230px;
  }
}

@media screen and (max-width: 950px) {
  .event-info {
    &__name {
      max-width: 208px;
    }

    &__date {
      max-width: 180px;
    }
  }

  .reports-info {
    &__date, &__starting-hour, &__hours-count, &__ending-hour, &__status, &__reporting-confirmation {
      max-width: 90px;
    }
  }

  .participant-reports .reports-info__comment {
    max-width: 175px;
  }

  .main-section {
    .search-fields-wrapper {
      flex-wrap: wrap;
    }

    .field-wrapper {
      max-width: none;
      flex-basis: calc(50% - 11px);
    }

    .participation-type-dropdown .dropdown-content {
      left: 0;
      right: auto;
    }

    .list-container {
      gap: 20px;

      ul {
        width: 180px;
      }
    }
  }
}

@media screen and (max-width: 860px) {
  .event-info {
    &__name {
      max-width: 167px;
    }

    &__date {
      max-width: 158px;
    }

    &__hours {
      max-width: 98px;
    }

    &__practice-hours {
      max-width: 77px;
    }

    &__status {
      max-width: 63px;
    }
  }

  .reports-info {
    &__date, &__starting-hour, &__hours-count, &__ending-hour, &__status, &__reporting-confirmation {
      max-width: 80px;
    }
  }
  .participant-reports .reports-info__comment {
    max-width: 158px;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  .card-container.banner-card {
    .card-img {
      flex-grow: 1;

      img {
        height: 100%;
      }
    }

    .card-text {
      padding: 34px 20px 39px;
    }
  }
}

@media screen and (max-width: 768px) {
  .card {
    &-wrapper {
      flex-wrap: nowrap;
      padding: 0 15px;
      margin: 0 -15px;
      overflow-x: auto;
      @include hideScroll;
    }

    &-container {
      flex-basis: auto;
      min-width: 233px;

      &:not(.banner-card) {
        order: 2;
      }

      &.banner-card {
        min-width: 342px;
        order: 1;
      }
    }

    &-column {
      .card-container {
        flex-basis: calc(50% - 27px / 2);
        min-width: auto;
      }

      .card-wrapper {
        flex-wrap: wrap;
      }
    }
  }

  .footer-section-link-wrapper {
    display: block;
  }

  .event {
    &-info {
      font-size: 16px;
      background: none;

      &__labels {
        display: none;
      }
    }

    &-name {
      display: block;
    }
  }

  .info {
    &-label {
      max-width: none;
      flex: 0 0 98px;
    }

    &-item {
      max-width: none;
      display: flex;
      gap: 22px;
      padding: 10px 0;
      border-bottom: 1px solid #f0f0f0;

      &:first-of-type {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }

      &.event-info__date {
        border-bottom: none;
        padding-bottom: 0;

        & ~ * {
          display: none;
        }
      }
    }

    &-row {
      display: block;
      background: $light-color;

      &:not(:last-child) {
        border-bottom: none;
        margin: 0 0 10px;
      }
    }
  }

  .cancel-event-btn {
    max-width: 293px;
    min-height: 33px;
    border: 1px solid $default-color;
    border-radius: 24px;
    margin: 24px auto 0;
    align-items: center;
    justify-content: center;
    font-weight: 400;
  }

  .filtered-label {
    cursor: default;

    &::after {
      content: none;
    }
  }

  .collapse-content-btn {
    display: block;
  }

  .collapsed-content {
    .event-info__date.info-item {
      border-bottom: 1px solid #f0f0f0;
      padding-bottom: 10px;
    }
  }

  .instructor-reports {
    &__top-block {
      display: block;
      margin: 0 0 39px;

      .event-progress-block {
        margin: 0;
      }
    }

    &__filtration-dropdown {
      margin: 0 0 18px;

      input {
        font-size: 16px;
        height: 46px;
      }
    }
  }

  .reports {
    &-info {
      &__labels {
        display: none;
      }

      &__row {
        display: none;
        align-items: flex-start;

        &:not(:last-child) {
          margin: 0 0 10px;
        }

        &:not(.add-report-wrapper) {
          padding: 19px 22px 19px 12px;
        }
      }
    }

    &-title {
      margin: 0 0 39px;

      .title-with-line {
        margin: 0;
      }
    }

    &-comment {
      display: block;

      &-wrapper {
        line-height: 1.35;
      }
    }
  }

  .add-report {
    &-wrapper {
      display: none;
    }

    &-btn {
      width: 36px;
      min-height: 36px;
      border-radius: 100%;
      font-size: 0;
      position: relative;

      &::before {
        font-size: 25px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
      }
    }
  }

  .report-author-info {
    &__content {
      display: block;
      font-size: 14px;
      padding: 15px 21px;

      p {
        display: flex;
        gap: 20px;

        &:not(:last-child) {
          padding: 0 0 15px;
          margin: 0 0 16px;
          border-bottom: 1px solid #f0f0f0;
        }
      }
    }

    &__email::after {
      content: none;
    }

    &__label {
      width: 85px;
      flex-shrink: 0;
    }

    &__name .report-author-info__label {
      display: block;
    }

    &__tel a {
      pointer-events: auto;
      color: $default-color;
      font-size: 16px;
    }
  }

  .dropdown-arrow {
    left: 9px;
  }

  .title-with-line {
    margin: 0 0 21px;
  }

  .participant-reports {
    .reports-info__comment {
      max-width: none;
    }

    .reports-info__row {
      min-height: auto;
    }
  }

  .project-info {
    margin: 0;

    &__image {
      margin: 38px 0 0;

      .badge {
        right: 0;
      }
    }
  }
  .share {
    margin: 0 0 20px;
  }
}

@media screen and (min-width: 640px) {
  .login__form input {
    @include placeholder {
      opacity: 0;
    }
  }
}

@media screen and (max-width: 639px) {
  input {
    &[type=text], &[type=tel], &[type=email], &[type=password] {
      padding: 0 17px;
    }
  }

  .loading-block {
    img {
      width: 125px;
    }
  }

  .cities-expanded-list {
    background-color: $default-color;
    max-width: 100%;
    flex-wrap: wrap;
    top: 0;
    padding: 17px 14px 28px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);

    &:not(.multicolumn-2) {
      width: calc(100vw - 18px);
      left: 9px !important;
      right: 9px !important;
    }

    ul {
      width: auto;
      padding: 0 30px 0;
      margin: 0;

      &:not(:last-child) {
        border-left: 1px solid $light-color;
      }
    }

    li {
      line-height: 1;
      padding: 10px 0;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  .main-section {
    background-image: url("../images/bg-top-section-mobile.png");
    padding: 56px 9px 32px;
    min-height: auto;
    margin: 0 0 44px;
  }

  .alert {
    padding: 0 9px;

    .content-container::before {
      content: none;
    }

    p {
      padding: 14px 10px;
    }
  }

  .dropdown input {
    padding: 0 9px;
  }

  textarea {
    height: 158px;
  }

  #footer {
    padding: 33px 0 0;
    border-top: 1px solid $light-grey;
  }

  .footer {
    &-nav {
      flex-wrap: wrap;
      justify-content: center;
      gap: 26px 63px;

      ul {
        display: block;
        font-size: 16px;
        margin: 0;
        order: 2;
        flex-basis: 36%;
      }

      li:not(:last-child) {
        border-left: 0;
        padding: 0;
        margin: 0 0 13px;
      }
    }

    &-logo {
      position: relative;
      order: 1;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: -31px;
        width: 1px;
        height: 100%;
        background: #d7d7d7;
      }

      svg {
        width: 128px;
        height: 51px;
      }
    }
  }

  .family-foundation-logo {
    flex-basis: 100%;
    background: #f4f4f4;
    padding: 8px 9px;
    text-align: center;
    box-sizing: border-box;
    order: 3;
  }

  .login {
    padding: 0;
    align-items: stretch;

    &__popup {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
    }

    &__info {
      padding: 54px 9px;
      flex-basis: 100%;
      display: block;

      h2 {
        margin: 0 0 20px;
      }
    }

    &__text-info {
      padding: 0 0 24px;
      border-bottom: 1px solid #e8e8e8;
      margin: 0 0 26px;
    }

    &__form {
      label {
        display: none;
      }

      input[type="tel"] {
        height: 40px;
        font-size: 14px;
      }
    }

    &__bottom-block {
      padding: 22px 9px;
      gap: 13px;
    }

    &__client-logo {
      max-width: 155px;
      padding: 0 0 0 15px;

      &::after {
        height: 169%;
      }
    }

    &__logo {
      max-width: 89px;
    }

    &__client-logo, &__logo {
      img {
        width: 100%;
      }
    }
  }

  .text-page {
    padding: 30px 9px 50px;

    h1 {
      padding: 0 0 22px;
    }
  }

  .contact-us {
    background: $light-color;
    padding: 57px 9px 36px;

    h1 {
      font-size: 24px;
      margin: 0 0 10px;
    }
  }

  .contact-form {
    &__wrapper {
      padding: 0;
    }

    .checkbox-wrapper {
      margin: 0 0 36px;

      label {
        font-weight: 700;
      }

      input {
        margin: 1px 0 0 4px;
      }
    }

    .btn {
      width: 100%;
    }
  }

  .thanks {
    padding: 49px 39px;
    background: $light-color;

    &-page {
      background: $light-color;
    }

    &__content {
      padding: 0;

      h2 {
        margin: 0 0 48px;
        line-height: 1.4;
      }

      .img-holder {
        margin: 0 auto 36px;
      }
    }
  }

  .single-volunteering .volunteering-title {
    margin: 0 0 31px;
  }

  .volunteering {
    padding: 0 9px 43px;

    &-title {
      padding: 23px 9px 10px;
      margin: 0 0 29px;

      h1 {
        font-size: 20px;
      }
    }
  }

  .account-nav {
    border-top: 1px solid $light-grey;
    padding: 26px 9px 0;
    margin: 0 -9px 32px;
    background: $light-color;
    overflow: auto;
    @include hideScroll;

    &::after {
      content: none;
    }

    ul {
      gap: 10px;
      min-width: 395px;
    }

    li {
      white-space: nowrap;
      padding: 0 10px 13px;
    }
  }

  .event {
    &-progress-block {
      font-size: 16px;
      padding: 6px 8px;
      margin: 0 0 50px;
      gap: 64px;

      &.only-progress-title {
        width: 100%;
      }
    }

    &-info__row {
      padding: 32px 9px 10px;
    }
  }

  .empty-info {
    margin: 50px 0;

    &::before, &::after {
      content: none;
    }

    &__btn {
      width: 100%;
      padding: 0 25px;
    }
  }

  .instructor-reports, .participant-reports {
    padding: 0 9px 43px;
  }

  .instructor-reports {
    &__list {
      gap: 12px;

      ul {
        flex-basis: calc(33.33% - 4px);
      }
    }

    &__filtration-dropdown .dropdown-content {
      padding: 28px 9px 45px;
    }
  }

  .new-report-popup {
    padding: 0;

    .close-popup {
      display: none;
    }

    .popup {
      &-content {
        min-height: 100vh;
        padding: 0;
        background: #f0f0f0;
        max-width: none;

        &__wrapper {
          max-width: none;
        }
      }

      &-form {
        background: $light-color;
        padding: 31px 28px;
        column-gap: 11px;

        &-wrapper {
          background: #f0f0f0;
          padding: 9px;
        }
      }
    }

    h2 {
      padding: 15px;
      background: $light-color;
      font-size: 18px;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 14px;
      margin: 0;

      &::before {
        content: "";
        width: 15px;
        height: 15px;
        background: url("../images/svg/black-arrow.svg") no-repeat center;
        background-size: contain;
        transform: rotateZ(-90deg);
      }
    }

    .field-wrapper {
      margin: 0 0 14px;

      &:first-of-type {
        flex-basis: 100%;

        input {
          width: 100%;
          margin: 0;
        }
      }

      &:nth-of-type(2), &:nth-of-type(3) {
        flex-basis: calc(50% - 5.5px);
      }

      &:last-of-type {
        margin: 0 0 16px;
      }
    }

    input {
      &[type="text"] {
        width: 100%;
      }

      &:not(.btn) {
        min-height: 35px;
      }
    }

    label {
      margin: 0 0 5px;
    }
  }

  .faq {
    padding: 30px 9px 32px;
    background: $light-color;

    &__list__item {
      padding: 0 0 33px;

      &:not(:last-child) {
        margin: 0 0 33px;
      }
    }
  }

  .card-section {
    padding: 0 9px;
    margin: 0 0 36px;
  }

  .card-wrapper {
    padding: 0 9px;
    margin: 0 -9px;
  }

  .record-form {
    &__section {
      padding: 0;
      margin: 0 0 36px;
      .thanks {
        padding: 0 30px;
      }
    }

    &__wrapper {
      padding: 30px 9px 39px;
    }

    &__info {
      font-size: 18px;
    }
  }
  .project-info {
    &__title {
      h1 {
        margin: 0 0 21px;
      }

      h2 {
        margin: 0 0 18px;
      }

      > h3::after {
        margin: 27px 0 0;
      }
    }
    &__description {
      margin: 0 0 33px;
    }
  }
  .contacts {
    padding: 7px 0 20px;
    h4 {
      margin: 0 0 24px;
    }
    &__info {
      flex-direction: column;
      align-items: flex-start;

      .card-info-item:not(:nth-child(3n)) {
        padding: 0;

        &::before {
          content: none;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .card {
    padding: 7px 7px 19px;

    h4 {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.8;

      &::after {
        content: none;
      }
    }

    &-description-wrapper {
      margin-bottom: 25px;
    }

    &-column {
      .card-container {
        max-width: 342px;
        flex-basis: auto;
      }

      .card-wrapper {
        flex-direction: column;
        align-items: center;
        gap: 16px;
      }

      &.card-section h2 {
        max-width: 342px;
        margin: 0 auto 22px;
      }
    }

    &-section {
      .title-separator {
        display: block;
      }

      .badge {
        right: -7px;
        padding: 4px 7px;
      }

      h2 {
        margin: 0 0 22px;
        line-height: 1.25;

        span {
          font-size: 16px;
        }
      }
    }

    &-footer {
      padding-top: 17px;

      .btn {
        line-height: 19px;
      }
    }

    .title-separator {
      min-height: 44px;

      &::before {
        top: 50%;
        width: 14px;
      }
    }

    &-img {
      margin-bottom: 8px;

      img {
        height: 151px;
        object-fit: cover;
        width: 100%;
      }
    }

    &-info-item {
      gap: 11px;
      font-size: 13px;
      margin: 0 0 46px;

      svg {
        width: 17px;
      }
    }

    &-wrapper {
      gap: 10px;
    }

    &-container.banner-card {
      min-width: 342px;
      order: 1;

      .card {
        padding: 7px;
      }

      .card-text {
        padding: 24px 34px 29px 27px;
      }

      .card-img img {
        height: 405px;
      }

      h4 {
        font-size: 18px;
      }
    }
  }

  .cities-expanded-list {
    &:not(.multicolumn-2) {
      ul {
        width: 33.33%;

        &:not(:last-child) {
          border-left: none;
        }

        &:not(:nth-of-type(3n)) {
          border-left: 1px solid $light-color;
        }
      }
    }
  }

  .main-section {
    h1 {
      text-align: center;
      font-weight: normal;
      font-size: 21px;
      margin: 0 0 26px;

      &::after {
        margin: 29px auto 0;
        width: 23px;
      }
    }

    h2 {
      text-align: center;
      font-size: 15px;
      margin: 0 0 57px;
      line-height: 1.45;

      strong {
        font-size: 17px;
        margin-top: 3px;
      }
    }

    h3 {
      text-align: center;
      font-size: 20px;
    }

    .field-wrapper {
      flex-basis: auto;
    }

    .dropdown-content {
      width: 100%;
      padding: 13px 12px 13px 10px;
    }

    .list-container {
      ul {
        width: auto;
        font-size: 15px;
      }

      li {
        margin: 0 0 22px;
      }

      &.location-list-container ul {
        width: auto;
      }
    }

    form {
      h3 {
        font-size: 20px;
      }
    }

    .checkbox-slider {
      margin: 0 auto 21px;
      justify-content: center;
    }

    .search-fields-wrapper {
      flex-direction: column;
      gap: 5px;
    }

    .input-with-icon {
      &::after {
        left: 13px;
      }

      input {
        padding: 0 52px 0 36px;
        height: 36px;
      }

      svg {
        right: 11px;
        max-width: 28px;
      }
    }

    .search-result {
      font-size: 16px;

      li {
        margin-bottom: 21px;

        &:nth-child(2) {
          margin-top: 20px;
        }

        b {
          font-size: 1.15em;
        }
      }
    }

    .participants-count-dropdown, .branches-dropdown, .category-dropdown {
      .input-with-icon input {
        padding-right: 52px;
      }
    }

    .cities-dropdown .input-with-icon svg {
      width: 14px;
    }

    .participants-count-dropdown .input-with-icon svg {
      width: 100%;
    }
  }

  .badge {
    top: 12px;
    right: -10px;
  }
}

@media screen and (max-width: 460px) {
  .instructor-reports {
    &__filtration-dropdown .dropdown-content {
      max-height: calc(100vh - 300px);
      overflow-y: auto;
    }

    &__list {
      flex-wrap: wrap;

      ul {
        flex-basis: 100%;
      }
    }
  }

  .project-info__image img {
    width: 100%;
  }
}