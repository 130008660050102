@import "../variables";
@import "../mixins";

.main-section {
  padding: 59px 15px 46px;
  color: $light-color;
  background: url('../images/bg-top-section.jpg') no-repeat;
  background-size: cover;
  min-height: 451px;
  box-sizing: border-box;
  margin: 0 0 59px;

  h1 {
    line-height: 1.34;
    letter-spacing: 1.6px;
    font-weight: 400;
    margin: 0 -3px 28px 0;

    &:after {
      content: '';
      width: 49px;
      height: 1px;
      background: $light-color;
      margin: 27px 5px 0 0;
      display: block;
    }
  }

  h2 {
    font-weight: 400;
    font-size: 22px;
    line-height: 1.55;
    margin: 0 0 96px;

    strong {
      display: block;
      font-size: 24px;
    }
  }

  .search-fields-wrapper {
    display: flex;
    gap: 22px;
  }

  .field-wrapper {
    max-width: 264px;
    width: 100%;
    background-color: $light-color;
    background-position: calc(100% - 13px) center;
    background-repeat: no-repeat;
    color: $dark-color;
    cursor: pointer;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin: 0;

    input {
      color: $default-color;
      @include placeholder {
        color: $dark-color;
        opacity: 1;
      }
    }
  }

  .input-with-icon {
    position: relative;
    width: 100%;
    pointer-events: none;

    svg {
      position: absolute;
      right: 13px;
      top: 50%;
      transform: translateY(-50%);
    }

    input {
      border: none;
      margin: 0;
      font-size: 16px;
      text-overflow: ellipsis;
      height: 46px;
      padding: 0 46px 0 50px;
    }
  }

  .dropdown-arrow {
    background-image: url("../images/svg/blue-arrow.svg");
  }

  .dropdown-content {
    width: auto;
    min-width: 100%;
    right: 0;
    left: auto;
    padding: 21px 34px 13px 15px;
    cursor: auto;

    &.overlayed {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(255, 255, 255, 0.7);
      }
    }
  }

  .list-container {
    display: flex;
    gap: 73px;

    ul {
      width: 212px;
      font-size: 14px;
      flex-shrink: 0;
    }

    li {
      margin: 0 0 15px;
      cursor: pointer;
    }

    &.location-list-container {
      gap: 20px;

      ul {
        width: 153.7px;
      }
    }
  }

  .search-input-wrapper {
    max-width: 469px;
    margin-bottom: 22px;
    position: relative;

    input {
      height: 38px;
      padding: 7px 12px;
      border: 1px solid #979797;
      @include placeholder() {
        color: $darker-grey;
      }
    }
  }

  .search-result {
    display: none;
    font-size: 18px;
    max-height: 260px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    background: $light-color;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.12);
    @include hideScroll;

    &.show {
      display: block;
    }

    li {
      margin-bottom: 20px;
      padding: 0 12px;
      cursor: pointer;

      &:first-child {
        margin-top: 9px;
      }
    }

    a {
      display: block;
    }
  }

  form {
    h3 {
      font-size: 22px;
      margin: 0 0 16px;
    }
  }

  .checkbox-slider {
    margin: 0 0 24px;
    font-size: 14px;
  }

  .checkbox-rail {
    margin: 0 13px 0 0;
  }

  .cities-dropdown, .category-dropdown {
    z-index: 6;
  }

  .participants-count-dropdown {
    z-index: 5;
    .input-with-icon svg {
      width: 36px;
      height: auto;
    }
  }

  .participants-count-dropdown {
    z-index: 4;
  }

  .participants-count-dropdown, .branches-dropdown {
    .input-with-icon input {
      padding-right: 59px;
    }
  }

  .category-dropdown .input-with-icon input {
    padding-right: 53px;
  }

  .branches-dropdown .dropdown-content {
    right: auto;
    left: 0;
  }
}

.card {
  position: relative;
  background-color: $light-color;
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 100%;
  height: 100%;
  box-sizing: border-box;

  &-section {
    padding: 0 15px;
    margin: 0 0 55px;

    h2 {
      font-size: 22px;
      letter-spacing: 0.1px;
      margin: 0 0 47px;

      &::before {
        margin: 1px 0 0;
      }

      span {
        padding-left: 8px;
        background-color: $body-color;
        font-weight: normal;
        font-size: 18px;
      }

      i {
        font-style: normal;
      }

      strong, b {
        font-size: 1.2em;
      }
    }
  }

  &-container {
    flex-basis: calc(33.33% - 54px / 3);
    position: relative;
    overflow: hidden;

    &.banner-card {
      .card-img {
        margin: 0;

        img {
          height: 439px;
        }
      }

      .card-text {
        background: $default-color;
        padding: 34px 39px 39px 27px;
        color: $light-color;
      }

      h4 {
        margin: 0 0 19px;
        height: auto;
      }

      p {
        margin: 0;
        line-height: 1.65;
      }
    }
  }

  &-wrapper {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    justify-content: flex-start;
    overflow: hidden;
    gap: 27px;
  }

  &-img {
    position: relative;
    margin: 0 0 18px;

    img {
      height: 208px;
      width: 100%;
      object-fit: cover;
    }
  }

  &-location-dropdown {
    position: absolute;
    right: 0;
    background: $default-color;
    padding: 15px 22px;
    display: none;

    ul {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: minmax(156px, 1fr);
      grid-template-rows: repeat(6, 1fr);
      grid-column-gap: 22px;
      color: $light-color;
      font-size: 15px;
      line-height: 27px;
      padding: 0;
      margin: 0;
      list-style: none;
    }

    &.show {
      display: block;
    }
  }

  &-title-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }

  h4 {
    font-size: 18px;
    line-height: 1.56;
    font-weight: 400;
    max-height: 90px;
    margin: 0;
    overflow: hidden;
    height: 84px
  }

  .title-separator {
    min-height: 48px;
    position: relative;
    flex-grow: 1;

    &::before {
      content: "";
      position: absolute;
      top: calc(50% - 5px);
      right: 0;
      width: 22px;
      height: 1px;
      background-color: #e3e3e3;
    }
  }

  &-description {
    line-height: 1.7;
    letter-spacing: 0.08px;
    margin: 0;

    &-wrapper {
      margin-bottom: 24px;
      font-size: 14px;
    }
  }

  &-info-item {
    display: flex;
    font-size: 14px;
    gap: 14px;
    margin: 0 0 26px;

    svg {
      width: 18px;
      flex-shrink: 0;
    }

    p {
      color: $darker-grey;
      margin: 0;
    }
  }

  &-location {
    position: relative;
    align-items: center;
    color: $darker-grey;
    line-height: 1.2;

    &.with-cities-list {
      cursor: pointer;
      @media screen and (min-width: 640px) {
        &:hover {
          color: $default-color;
        }
      }
    }

    p {
      max-height: 45px;
      margin: 0;
    }

    svg {
      width: 16px;
      margin: 0 0 0 2px;
    }

    &.show {
      .card-location-dropdown {
        display: block;
      }
    }
  }

  .working-mode {
    max-height: 34px;
    overflow: hidden;
  }

  &-description, .vacancies p, .time p {
    @include cutText {
      -webkit-line-clamp: 1;
    }
  }

  &-footer {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    border-top: 1px solid $light-grey;
  }

  div:nth-last-child(2){
    margin: 0 0 13px;
  }
}

.cities-expanded-list {
  display: none;
  top: 100%;
  right: 0;
  padding-top: 7px;
  z-index: 15;
  cursor: default;
  box-sizing: border-box;
  visibility: hidden;
  pointer-events: none;
  position: fixed;

  ul {
    background-color: #298cca;
    font-size: 14px;
    line-height: 27px;
    color: $light-color;
    padding: 12px 22px;
    width: 183px;
    display: block;
    list-style: none;
    box-sizing: border-box;
  }
}

.show-new-list {
  .cities-expanded-list {
    display: flex;
    visibility: visible;
    pointer-events: all;
  }
}

.alert {
  padding: 0 15px;
  margin: 0 0 55px;

  .content-container {
    display: flex;
    gap: 3px;

    &::before {
      content: "";
      display: block;
      min-height: 48px;
      width: 17px;
      background: $default-color;
      flex-shrink: 0;
    }
  }

  p {
    background: $light-color;
    font-size: 16px;
    padding: 10px 11px;
    box-sizing: border-box;
    margin: 0;
    flex-grow: 1;
    line-height: 1.7;
  }
}