.contact-us .MuiFormControl-root{
    width: 100%;
    font: 14px "AlmoniDL",Arial,sans-serif;
}

.contact-us .MuiInputBase-root input[type="text"]{
    padding-right: 25px !important;
    font-size: 16px;
    border: none;
    height: 3em;
}

.contact-us .MuiInputLabel-outlined{
    font-size: 16px;
}

.contact-us .MuiInputLabel-shrink{
    right: 16px;
}

.contact-us .MuiInputBase-root fieldset {
    text-align: right;
    right: 0px;
}

.contact-us fieldset legend{
    font-size: 1.45em;
}

.contact-us .MuiFormControl-root {
    margin-right: 0px !important;
}

.contact-us p.error-message{
    display: block;
}

textarea.textarea_error{
    border: 1px solid #ed1b2e;
    color: #ed1b2e;
}

textarea.textarea_error::placeholder{
    color: #ed1b2e;
}

p.helper_text{
    line-height: 1.66;
    letter-spacing: 0.03333em;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 5px;
    margin-left: 14px;
    font-size: 14px;
    border-color: #ccc9c9;
}

p.helper_text.textarea_error{
    color: #ed1b2e;
}

.contact-us label, .contact-us p{
    font-family: "AlmoniDL",Arial,sans-serif !important;
}

.colored_block{
    width: 100%;
    height: 188px;
    background-color: #ed1b2e;
    position: absolute;
}
