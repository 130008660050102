.datePicker {
    background-color: rgb(255, 255, 255);
    width: 240px;
}

.datePicker:hover {
    cursor: pointer;
}

.datePicker .MuiInputBase-root fieldset {
    border: unset;
}

.datePicker .MuiPaper-root .MuiTabs-indicator {
    border-color: 'white'
}
.datePicker .MuiPaper-root .MuiTab-root.Mui-selected {
    border-color: 'white'
}
.datePicker .MuiBox-root > .rdrCalendarWrapper {
    direction: rtl;
    top: -1px;
    border: 1px solid;
    border-color: #e2e2e2;
    margin-right: -9px;
    box-shadow: 0 2px 7px 0 rgba(185, 185, 185, 0.5);
}


.datePicker button.rdrDayStartOfWeek .rdrEndEdge {
    border-left-width: 1px;
    border-top-right-radius: 1.042em;
    border-bottom-right-radius: 1.042em;
    border-top-left-radius: 1.042em;
    border-bottom-left-radius: 1.042em;
}
.datePicker button:has(.rdrDayStartPreview) > .rdrStartEdge.rdrEndEdge {
    right: 0;
    left: 0;
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
    border-top-right-radius: 1.042em;
    border-bottom-right-radius: 1.042em;
}
.datePicker button:has(.rdrDayEndPreview) > .rdrStartEdge.rdrEndEdge {
    right: 0;
    left: 0;
    border-top-left-radius: 1.042em;
    border-bottom-left-radius: 1.042em;
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
}

.datePicker button:has(.rdrDayStartPreview):has(.rdrDayEndPreview)  .rdrStartEdge.rdrEndEdge {
    right: 0;
    left: 0;
    border-top-left-radius: 1.042em;
    border-bottom-left-radius: 1.042em;
    border-top-right-radius: 1.042em;
    border-bottom-right-radius: 1.042em;
}

.datePicker button > .rdrEndEdge, 
.datePicker button.rdrDayEndOfMonth .rdrInRange,
.datePicker button.rdrDayEndOfMonth.rdrDayActive .rdrInRange,
.datePicker button > .rdrDayEndPreview, 
.datePicker button > .rdrDayWeekend,
.datePicker button.rdrDayEndOfMonth .rdrDayEndPreview,
.datePicker button.rdrDayEndOfMonth .rdrDayInPreview,
.datePicker button.rdrDayEndOfWeek .rdrDayEndPreview,
.datePicker button.rdrDayEndOfWeek.rdrDayHovered .rdrDayEndPreview,
.datePicker button.rdrDayEndOfWeek .rdrDayInPreview ,
.datePicker button.rdrDayEndOfWeek .rdrInRange{
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
    border-right: unset;
    right: -2px;
    border-left-width: 1px;
    border-top-left-radius: 1.042em;
    border-bottom-left-radius: 1.042em;
    left: 2px;
}

.datePicker button.rdrDayEndOfWeek.rdrDayHovered > .rdrDayStartPreview {

    border-top-left-radius: 1.042em;
    border-bottom-left-radius: 1.042em;
    border: 1px solid currentColor;
}

.datePicker button.rdrDayEndOfWeek.rdrDayHovered > .rdrDayStartPreview.rdrDayEndPreview {
    border-top-right-radius: 1.042em;
    border-bottom-right-radius: 1.042em;
    border: 1px solid currentColor;
}
.datePicker button.rdrDayEndOfWeek > .rdrDayStartPreview {
    border: 1px solid currentColor;
    border-top-left-radius: 1.042em;
    border-bottom-left-radius: 1.042em;
    border-top-right-radius: 1.042em;
    border-bottom-right-radius: 1.042em;
}

.datePicker button.rdrDayEndOfWeek > .rdrDayStartPreview.rdrDayEndPreview {
    right: 0;
}

.datePicker button .rdrDayWeekend .rdrDayStartOfWeek > .rdrStartEdge.rdrEndEdge{
    border-left-width: 1px;
    border-top-left-radius: 1.042em;
    border-bottom-left-radius: 1.042em;
}
.datePicker button.rdrDayEndOfWeek:has(.rdrDayStartPreview) > .rdrStartEdge.rdrEndEdge {
    border-top-left-radius: 1.042em;
    border-bottom-left-radius: 1.042em;
}
.datePicker button.rdrDayEndOfWeek .rdrStartEdge {
    border-left-width: 1px;
    border-top-left-radius: 1.042em;
    border-bottom-left-radius: 1.042em;
}

.datePicker button.rdrDayEndOfMonth > .rdrDayStartPreview {
    border: 1px solid currentColor;
    border-top-right-radius: 1.042em;
    border-bottom-right-radius: 1.042em;
}

.datePicker button.rdrDayEndOfMonth > .rdrStartEdge {
    border-top-left-radius: 1.042em;
    border-bottom-left-radius: 1.042em;
}
.datePicker button.rdrDayEndOfMonth > .rdrStartEdge.rdrEndEdge {
    right: 0;
    border-top-left-radius: 1.042em;
    border-bottom-left-radius: 1.042em;
}
.datePicker button.rdrDayEndOfMonth > .rdrDayStartPreview {
    border-top-left-radius: 1.042em;
    border-bottom-left-radius: 1.042em;
}

.datePicker button.rdrDayEndOfMonth.rdrDayHovered > .rdrDayStartPreview {
    border-top-left-radius: 1.042em;
    border-bottom-left-radius: 1.042em;
    right: -2;
}
.datePicker button.rdrDayEndOfMonth > .rdrDayStartPreview.rdrDayEndPreview {
    right: 0;
}

.datePicker button > .rdrStartEdge,
.datePicker button.rdrDayStartOfMonth .rdrInRange,
.datePicker button.rdrDayStartOfMonth .rdrDayInPreview,
.datePicker button.rdrDayStartOfMonth.rdrDayActive .rdrInRange,
.datePicker button > .rdrDayStartPreview, 
.datePicker button.rdrDayStartOfWeek .rdrDayStartPreview,
.datePicker button.rdrDayStartOfWeek.rdrDayHovered .rdrDayStartPreview,
.datePicker button.rdrDayStartOfWeek .rdrDayInPreview,
.datePicker button.rdrDayStartOfWeek .rdrInRange {

    border-left: unset;
    border-right-width: 1px ;
    border-top-width: 1px ;
    border-top-right-radius: 1.042em;
    border-bottom-right-radius: 1.042em;
    right: 0px;
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
    left: 0px;
}

.datePicker button > .rdrStartEdge.rdrEndEdge {
    border-top-left-radius: 1.042em;
    border-bottom-left-radius: 1.042em;
}

.datePicker button.rdrDayStartOfWeek.rdrDayHovered .rdrDayEndPreview {
    right: 0px;
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
    

}

.datePicker button.rdrDayStartOfWeek.rdrDayHovered .rdrDayStartPreview.rdrDayEndPreview {
    left: 2px;
    border-top-left-radius: 1.042em;
    border-bottom-left-radius: 1.042em;
    border: 1px solid currentColor;
}

.datePicker button.rdrDayStartOfWeek .rdrStartEdge.rdrEndEdge {
    border-top-right-radius: 1.042em;
    border-bottom-right-radius: 1.042em;

}

.datePicker button.rdrDayStartOfWeek .rdrDayEndPreview {
    right: 0;
    border: 1px solid currentColor;
    border-top-right-radius: 1.042em;
    border-bottom-right-radius: 1.042em;

}

.datePicker button.rdrDayStartOfMonth > .rdrStartEdge.rdrEndEdge {
    right: 0px;
    border: 1px solid currentColor;

    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
}

.datePicker button.rdrDayStartOfMonth > .rdrDayEndPreview {
    right: 0px;
    border: 1px solid currentColor;

    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
}

.datePicker button.rdrDayStartOfMonth .rdrEndEdge {
    right: 0px;
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
}

.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
    bottom: 3px;
    top: 3px;
}

.datePicker .rdrNextPrevButton.rdrPprevButton {
    transform: scaleX(-1);
}

.datePicker button.rdrNextPrevButton.rdrNextButton {
    transform: scaleX(-1);
}
.datePicker button.rdrNextPrevButton.rdrNextButton > i{
    transform: translate(-3px, 0px);
}

.datePicker .rdrMonthAndYearWrapper .rdrMonthAndYearPickers select{
    font-family: 'AlmoniDL';
}

.datePicker button > .rdrDayStartPreview.rdrDayEndPreview
{   left: 2px;
    border: 1px solid currentColor;
    border-top-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-top-left-radius: 1em;
    border-bottom-left-radius: 1em;
}



@media screen and (max-width: 900px){
    .datePicker {
        margin-bottom: 20px !important;
        width: 100%;
    }

    .datePicker .MuiInputBase-root {
        width: 100%;
    }

    .datePicker .rdrMonths {
        display: unset;
    }

}
