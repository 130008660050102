.container-panel .icon-close-dialog {
    left: 1px;
    top: 1px;
}
.dashboardDialog {
    border-color: #e2e2e2;
    min-width: 804px;
    min-height: 536px;

    /* max-width: 1115px; */
    max-height: 783px;

    padding: 10px;

}

.dashboardDialog .rdrCalendarWrapper {
    direction: rtl;
    top: unset;
    border: 1px solid;
    border-color: #e2e2e2;
    margin-left: -9px;
    box-shadow: 2.0px 4.0px 4.0px hsl(0deg 0% 0% / 0.44);
}



#dashboardDonutLabel span {
    margin-left: 3rem;
    font-size: large;
    display: contents;
}

#dashboardDonutGraph .dashboardDonutInfo {

    padding: 0 13px;
    padding-top: 3em;
    font-size: 21px;
    text-align: center;
}
#dashboardDonutGraph .dashboardDonutInfo p {
    padding: 0 4px;
}

.filterSelect{
    height: 39px;
    min-width: 120px;
    direction: rtl;
    border: 1px solid #d7d7d7 !important;
}

.popup_loader{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 48vh;
}