@font-face {
  font-family: "AlmoniDL";
  src: url("../fonts/almoni-dl-aaa-400.eot");
  src: url("../fonts/almoni-dl-aaa-400.eot?#iefix") format("embedded-opentype"),
  url("../fonts/almoni-dl-aaa-400.woff") format("woff"),
  url("../fonts/almoni-dl-aaa-400.ttf") format("truetype"),
  url('../fonts/almoni-dl-aaa-400.svg') format('svg'),
  url('../fonts/almoni-dl-aaa-400.svg') format('opentype');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "AlmoniDL";
  src: url("../fonts/almoni-dl-aaa-700.eot");
  src: url("../fonts/almoni-dl-aaa-700.eot?#iefix") format("embedded-opentype"),
  url("../fonts/almoni-dl-aaa-700.woff") format("woff"),
  url("../fonts/almoni-dl-aaa-700.ttf") format("truetype"),
  url('../fonts/almoni-dl-aaa-700.svg') format('svg'),
  url('../fonts/almoni-dl-aaa-700.svg') format("opentype");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "AlmoniDL";
  src: url("../fonts/almoni-dl-aaa-300.eot");
  src: url("../fonts/almoni-dl-aaa-300.eot?#iefix") format("embedded-opentype"),
  url("../fonts/almoni-dl-aaa-300.woff") format("woff"),
  url("../fonts/almoni-dl-aaa-300.ttf") format("truetype");
  //url('../fonts/almoni-dl-aaa-300.svg') format('svg'),
  //url('../fonts/almoni-dl-aaa-300.svg') format("opentype");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}




