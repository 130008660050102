@import "../variables";
@import "../mixins";

.single-volunteering .volunteering-title {
  margin: 0 0 22px;
}

.volunteering {
  padding: 28px 30px 52px;
  box-sizing: border-box;

  &-title {
    background: $light-color;
    padding: 40px 30px 16px;
    text-align: center;

    h1 {
      font-size: 32px;
      margin: 0;
    }
  }
}

.account-nav {
  max-width: 690px;
  margin: 0 auto 47px;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    background: $light-grey;
  }

  ul {
    display: flex;
    justify-content: center;
    gap: 58px;
    padding: 0;
    margin: 0;
    font-size: 16px;
    list-style: none;
  }

  li {
    box-sizing: border-box;
    position: relative;
    color: $darker-grey;
    padding: 0 16px 7px;
    &:hover {
      color: $dark-color;
    }

    &.active {
      color: $dark-color;

      &::after {
        content: "";
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 2px;
        background: $default-color;
        bottom: 0;
        left: 0;
      }
    }
  }
}

.event {
  &-progress-block {
    background: $light-color;
    box-sizing: border-box;
    padding: 21px 13px;
    font-size: 18px;
    display: flex;
    align-items: center;
    gap: 38px;
    margin: 0 0 41px;

    &.only-progress-title {
      display: inline-block;
    }

    p {
      margin: 0;
    }
  }

  &-progress {
    flex: 1 1 auto;
    height: 7px;
    background: #e3e3e3;
    border-radius: 5px;
    overflow: hidden;

    .ui-progressbar-value {
      background: $default-color;
      height: 100%;
    }
  }

  &-info {
    background: $light-color;

    &__labels {
      box-sizing: border-box;
      padding: 16px 14px 15px;
      border-bottom: 1px solid #d0d0d0;
    }

    &__labels, &__row {
      display: flex;
      gap: 9px;
      letter-spacing: 0.18px;
    }

    &__row {
      position: relative;
      padding: 23px 14px 20px;
      box-sizing: border-box;

      &:not(:last-child) {
        border-bottom: 1px solid #f0f0f0;
      }
    }

    &__name {
      max-width: 400px;
    }

    &__date {
      max-width: 260px;
    }

    &__hours {
      max-width: 129px;
    }

    &__practice-hours {
      max-width: 100px;
    }

    &__status {
      max-width: 63px;
    }
  }

  &-name {
    @include cutText {
      -webkit-line-clamp: 1;
    }
  }
}

.info {
  &-label {
    font-weight: 400;
    color: $darker-grey;
    width: 100%;
  }

  &-item {
    width: 100%;

    p {
      margin: 0;
    }
  }
  
  &-row {
    position: relative;
  }
}

.cancel-event-btn {
  max-width: 90px;
  font-size: 16px;
  font-weight: 700;
  color: $default-color;
  background: none;
  padding: 0;
  margin: 0 auto 0 0;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.filtered-label {
  cursor: pointer;
  padding: 0 0 0 21px;
  box-sizing: border-box;
  position: relative;
  &::after {
    content: "";
    width: 17px;
    height: 17px;
    background: url("../images/filtered-arrows.png") no-repeat center;
    background-size: 9px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    border-radius: 50%;
  }
  &:hover::after {
    background-color: #f3f3f3;
  }
}

.collapse-content-btn {
  font-size: 0;
  background: url("../images/svg/black-arrow.svg") no-repeat center;
  background-size: 11px 6px;
  display: none;
  cursor: pointer;
  width: 28px;
  height: 23px;
  position: absolute;
  top: 0;
  left: 0;
}

.green-status {
  color: #0eae00!important;
}

.empty-info {
  position: relative;
  width: 100%;
  text-align: center;
  margin: 287px 0;

  &::before, &::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 1px;
    width: calc(50% - 153px);
    background: #d4d4d4;
  }

  &::before {
    right: 0;
  }

  &::after {
    left: 0;
  }

  &__btn {
    width: auto;
    padding: 0 67px;
    margin: 0 auto;
    letter-spacing: 0.19px;
  }
}