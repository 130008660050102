.main-dashboard {
    background: #f0f0f0;
}
.main-dashboard > .text-page {
    background-color: #f0f0f0 !important;
}

.main-dashboard .MuiFormControl-root {
    width: 100%;
}

.main-dashboard .section {
    margin-bottom: 10rem;
}
.main-dashboard .section-datePicker {
    margin-bottom: 4rem;
}

.main-dashboard .section-volunteer {
    margin-bottom: 2rem;
}

.main-dashboard .section-table {
    margin-bottom: 4rem;
}

.main-dashboard .content-container h1:after {
    content: none;
}
.dot {
    height: 15px;
    width: 15px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    margin: 0 5px;
}

.main-dashboard .content-container {
    max-width: 80%;
}

.section-datePicker input:hover,
.section-datePicker svg:hover{
    cursor: pointer;
}

@media screen and (max-width: 900px) {
    .main-dashboard .text-page {
        padding: 10px;
    }
    .main-dashboard .content-container {
        max-width: 91%;
    }
}