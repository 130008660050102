:root{
    --text-blue: #298cca;
}

.text-center{
    text-align: center;
}

.btn_spinner.MuiCircularProgress-root{
    width: 17px !important;
    height: 17px !important;
    position: absolute;
    left: 10px;
}

.center{
    text-align: center;
}

.error_page{
    text-align: center;
    margin-top: 200px;
    font-size: 50px;
}

.alert .content-container::before {
    background: unset;
    content: unset;
}

.logout_link{
    color: #b0adad;
    cursor: pointer;
}

.user_name_title{
    margin: 0 4px;
}

.d-flex{
    display: flex;
}

.justify-content-center{
    justify-content: center;
}

.w-100{
    width: 100%;
}

.header_logo{
    max-height: 74px;
}

.alert .content-container::before{
    content: none !important;
}

#dashboard_appbar .MuiButtonBase-root.MuiIconButton-root:hover {
    background-color: transparent !important;
}

.text-blue::placeholder{
    color: var(--text-blue) !important;
}

.main-section .filter-fields-dropdown .input-with-icon svg {
    width: 36px;
    height: auto;
}

.main-section .filter-fields-dropdown .input-with-icon input{
    padding-right: 59px;
}

.card-wrapper{
    justify-content: start !important;
}

.main_page_link{
    font-weight: 400 !important;
}

.dropdown .dropdown-content{
    visibility: hidden !important;
    opacity: 0;
    transition: all 0.3s ease-in-out !important;
    z-index: -999 !important;
}

.dropdown.show .dropdown-content{
    visibility: visible !important;
    opacity: 1;
    z-index: -1;
}

.dropdown .dropdown-arrow{
    transition: all 0.3s ease-in-out !important;
}

/* .dropdown.show .dropdown-arrow{
    transform: translateY(-50%) rotate(540deg) !important;
} */

.relative{
    position: relative;
}

.pointer{
    cursor: pointer;
}

.main-section .input-with-icon input{
    padding: 0 49px 0 50px !important;
}

.checkbox-slider {
    display: inline-flex !important;
}

@media screen and (max-width: 576px) {
    .main-section .dropdown-content{
        z-index: 99 !important;
    }
    .card-container.banner-card {
        min-width: 290px !important;
    }
}

@media screen and (max-width: 639px){
    .account-nav ul {
        min-width: unset;
        
    }

    .account-nav{
        overflow-x: scroll;
        max-width: 100%;
    }

    .toggle-menu-btn{
        left: 10px !important;
    }

    .menu-close-btn{
        left: 17px !important;
        top: 13px !important;
    }

    .header_logo{
        max-height: 54px;
        width: auto;
    }

    .header-nav li .active {
        font-size: 18px !important;
    }
}

@media screen and (max-width: 992px){
    .header__name-block{
        display: none !important;
    }
    .header-collapse-backdrop {
        top: -100% !important;
        opacity: 1 !important;
        transition: top .4s ease-in-out !important;
    }
    .header-collapse-backdrop.animate{
        top: 0 !important;
        opacity: 1 !important;
    }
}

@media screen and (min-width: 768px){
    .dropdown{
        z-index: 3 !important;
    }
}

@media screen and (max-width: 768px){
    .card-container {
        min-width: 290px !important;
    }
    .card-container:not(.banner-card) {
        order: unset !important;
    }
    .card-container{
        order: unset !important;
    }
}
