#footer {
  background-color: $light-color;
  padding: 15px 30px;
}

.footer {
  &-nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 45px;

    ul {
      font-size: 12px;
      list-style: none;
      padding: 0;
      margin: 0 0 0 auto;
      display: flex;
      letter-spacing: -0.2px;
      line-height: 12px;
    }

    li:not(:last-child) {
      border-left: 1px solid $dark-color;
      padding-left: 9px;
      margin-left: 9px;
    }
  }

  &-logos-wrapper {
    display: flex;
    gap: 45px;
  }

  &-logo svg {
    width: 101px;
    height: 31px;
    display: block;
  }
}
