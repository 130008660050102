
.block {
    display: flex;
    align-items:center;
    vertical-align: middle;

    background: #ffffff;

}
.icon-label {
    margin: 1rem;
    display: flex;
}

.icon-label>svg {
    font-size: 3rem;
}
.label-text {
    color: #000;
    margin-left: 1rem;
    display:inline;
    font-size: medium;
    display:inline-flex;
}
 .label-number {
    font-size: 3rem;
    font-weight: bold;
    color: #298cca;
}
 .section-table .MuiGrid-item{
    padding: 14px 0;
}

@media screen and (max-width: 600px){

    .instructor-reports__top-block .block {
        display: flex;
        align-items: center;
        vertical-align: middle;
        flex-direction: column;
    }

}
