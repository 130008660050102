.clear_filters_btn{
    padding: 5px 10px;
    margin: 7px 0;
    cursor: pointer;
}

/* .filter_cross::before{
    content: "\2715";
    position: absolute;
    top: -1px;
    right: 5px;
    color: #000;
    font-size: 35px;
} */
