@import "variables";

#header {
  padding: 0 31px;
  position: fixed;
  top: 0;
  width: 100%;
  background: $light-color;
  z-index: 101;
  box-sizing: border-box;
}

.header {
  &-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    max-width: 1322px;
    margin: 0 auto;
    min-height: 74px;
  }

  &__name-block {
    display: flex;
    align-items: center;
    gap: 15px;

    &.without-avatar strong {
      display: inline;
    }

    strong {
      display: block;
      font-size: 18px;
    }
  }

  &-nav {
    flex-basis: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 31px;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      letter-spacing: 0.28px;

      .active {
        font-weight: 700;
        font-size: 15px;
      }

      a:hover {
        color: $darker-grey;
      }
    }

    a {
      display: block;
    }
  }
}

.menu-close-btn {
  display: none;
  color: $dark-color;
  position: absolute;
  top: 16px;
  left: 7px;
  width: 28px;
  height: 28px;
  transform: rotateZ(45deg);

  &::before, &::after {
    content: "";
    width: 100%;
    height: 3px;
    border-radius: 20px;
    background: $dark-color;
    top: 50%;
    left: 0;
    position: absolute;
  }

  &::before {
    transform: translateY(-50%);
  }

  &::after {
    transform: translateY(-50%) rotateZ(90deg);
  }
}

.toggle-menu-btn {
  display: none;
  width: 25px;
  height: 19px;
  position: relative;
  justify-content: center;
  align-items: center;

  .menu-icon {
    width: 100%;
    height: 3px;
    background: $dark-color;
    display: block;

    &::before, &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 3px;
      background: inherit;
    }

    &::before {
      top: 8px;
    }

    &::after {
      top: 16px
    }
  }
}

.breadcrumb {
  display: none;
  font-weight: normal;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: -30px;
    right: -30px;
    top: 0;
    height: 1px;
    background: #e7e7e7;

  }

  ul {
    font-size: 12px;
    display: block;
    padding: 4px 0;
    color: #909090;

    li {
      display: inline;
      letter-spacing: 0.7px;
      position: relative;

      &:not(:last-child) {
        padding: 0 0 0 11px;
        margin: 0 0 0 1px;

        &::after {
          content: '>';
          font-size: 10px;
          line-height: 1;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
      }

      &.active {
        a {
          color: $default-color;
        }
      }

      strong, b {
        font-size: 1.1em;
      }
    }
  }

  .breadcrumb-container {
    min-height: auto;
  }
}

.has-breadcrumb {
  .breadcrumb {
    display: block;
  }

  #main {
    margin-top: 98px;
  }
}

@media screen and (max-width: 992px) {
  #header {
    border-bottom: 1px solid $light-grey;
  }
  .header {
    &-container {
      min-height: 49px;
      flex-direction: row-reverse;
    }

    &-collapse-backdrop {
      display: none;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, .7);
      opacity: 0;
      transition: opacity .5s ease;
      z-index: 1;

      &.show {
        display: block;

        .header-collapse {
          display: block;
        }

        .menu-close-btn {
          display: block;
        }
      }

      &.animate {
        opacity: 1;
      }
    }

    &-collapse {
      display: none;
      position: absolute;
      flex-direction: column;
      top: 0;
      width: 100%;
      background: $light-color;
      left: 0;
      padding: 66px 9px 42px;
      box-sizing: border-box;
    }

    &-nav {
      flex-direction: column;
      gap: 27px;
      align-items: flex-start;
      font-size: 18px;

      li.active {
        font-size: 20px;
      }
    }

    &__name-block {
      padding: 0 0 35px;
      border-bottom: 1px solid $light-grey;
      margin: 0 0 24px;
    }
  }
  .client-logo {
    max-width: 101px;
  }
  .breadcrumb-container {
    justify-content: flex-start;
  }

  .has-breadcrumb #main {
    margin-top: 68px;
  }

  .toggle-menu-btn {
    display: block;
  }
}

@media screen and (max-width: 639px) {
  #header {
    padding: 0 9px;
  }
  .breadcrumb::before {
    left: -9px;
    right: -9px;
  }
}

@media screen and (max-width: 576px) {
  .breadcrumb ul {
    font-size: 11px;
    padding: 3px 0 2px;
    letter-spacing: 1.35px;

    li:not(:last-child)::after {
      font-size: 8px;
    }
  }
}
