:root {
    /* --client-color: #ed1b2e; */
}

#header {
    border-bottom: 3px solid var(--client-color);
}

.header-without-line #header {
    border-bottom: none;
}

.login__popup {
    border-top: 7px solid var(--client-color);
}

.account-nav li.active::after {
    background: var(--client-color);
}

.event-progress .ui-progressbar-value {
    background: var(--client-color);
}

.volunteering-title {
    border-bottom: 4px solid var(--client-color);
}

.new-report-popup .popup-content {
    border-top: 4px solid var(--client-color);
}

.card-container.banner-card .card-text {
    background: var(--client-color);
}

.alert .content-container::before {
    background: var(--client-color);
}

@media screen and (min-width: 640px) {
    .thanks::after {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 188px;
        background: var(--client-color);
    }
}

@media screen and (max-width: 639px) {
    #header {
        border-bottom: 1px solid #e0e0e0;
    }

    .home #header {
        border-bottom: 2px solid var(--client-color);
    }

    .login__popup {
        border-top: none;
        border-bottom: 6px solid var(--client-color);
    }
    .new-report-popup .popup-content {
        border-top: none;
    }
    .new-report-popup h2 {
        border-bottom: 2px solid var(--client-color);
    }
    .volunteering-title {
        border-bottom-width: 2px;
    }
    .alert p {
        border-top: 4px solid var(--client-color);
    }
}
