@import "variables";

.btn {
  width: 179px;
  min-height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  font-family: $primary-font-family;
  line-height: 1.2;
  border: none;

  &.btn-blue {
    background-color: $blue-color;

    &:hover {
      background-color: #52a7dc;
    }
  }
}

.footer-section-link {
  color: $default-color;
  font-size: 16px;
  letter-spacing: 0.29px;
  border-bottom: 1px solid $default-color;
  font-weight: 700;

  &-wrapper {
    display: none;
    text-align: center;
    margin: 31px 0 0;
  }
}

.title-with-line {
  position: relative;
  font-weight: 400;
  font-size: 16px;
  margin: 0 0 39px;

  span {
    position: relative;
    background: #f0f0f0;
    z-index: 1;
    padding: 0 0 0 5px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $light-grey;
  }

  &.right-title {
    @media screen and (max-width: 639px) {
      &::before {
        content: none;
      }
    }
  }

  strong {
    font-size: 1.1em;
  }

  a {
    color: $blue-color;
    text-decoration: underline;
  }
}

.checkbox-slider {
  cursor: pointer;
  display: flex;
  align-items: center;

  p {
    margin-left: 10px;
  }

  .checkbox-rail {
    width: 46px;
    height: 24px;
    background: $light-color;
    position: relative;
    border-radius: 20px;

    span {
      position: absolute;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: $default-color;
      top: 50%;
      transform: translateY(-50%);
      transition: right .3s ease;

      &::before, &::after {
        content: '';
        width: 10px;
        height: 2px;
        background: $light-color;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%);
        margin-top: -1px;
        opacity: 0;
        transition: opacity .15s ease;
      }

      &::before {
        transform: translateX(-50%) rotateZ(45deg);
      }

      &::after {
        transform: translateX(-50%) rotateZ(-45deg);
      }
    }
  }

  input[type="checkbox"] {
    position: absolute;
    width: 0;
    height: 0;

    ~ .checkbox-rail span {
      right: 24px;
    }

    &:checked {
      ~ .checkbox-rail span {
        right: 4px;

        &::before, &::after {
          opacity: 1;
        }
      }
    }

    &:focus + .checkbox-rail {
      outline: 1px auto -webkit-focus-ring-color;
    }

    &.focusMouse + .checkbox-rail {
      outline: none;
    }
  }
}

h2 b {
  font-size: 1.1em;
}

.badge {
  position: absolute;
  top: 14px;
  right: -20px;
  letter-spacing: 0.09px;
  padding: 4px 18px;
  font-size: 16px;
  display: block;
  background-color: #fcd314;
  z-index: 1;
  font-weight: normal;
  margin: 0;
}

.skip-to-content-btn {
  position: fixed;
  top: 104px;
  right: 0;
  transform: translateX(100%);
  padding: 10px 20px;
  background-color: $blue-color;
  color: $light-color;
  width: 0;
  opacity: 0;
  @media screen and (max-width: 1024px) {
    display: none;
  }

  &:focus {
    transform: translateX(0);
    z-index: 2;
    width: auto;
    opacity: 1;
  }
}

.close-btn-wrapper-mobile {
  position: relative;
  width: 100%;
  height: 20px;
  margin: 0 0 12px;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, .17);
    left: 0;
    top: 50%;
  }

  .btn-wrapper {
    position: absolute;
    width: 20px;
    height: 20px;
    background: $default-color;
    padding: 0 15px;
    left: 0;
  }

  .close-btn {
    position: absolute;
    width: 26px;
    height: 26px;
    top: 50%;
    left: -2px;
    transform: translateY(-50%) rotateZ(45deg);

    &::before, &::after {
      content: "";
      width: 100%;
      height: 2px;
      border-radius: 20px;
      background: $dark-color;
      top: 50%;
      left: 0;
      position: absolute;
    }

    &::before {
      height: 100%;
      transform: translateX(-50%);
      width: 2px;
      top: 0;
      left: 50%;
    }

    &::after {
      transform: translateY(-50%);
    }
  }
}

.focusMouse {
  outline: none;
}

.sr-only {
  position: absolute;
  top: auto;
  width: 0;
  height: 0;
  overflow: hidden;
}
