.placeholder {
	position: relative;
}
.placeholder::before, .placeholder::after {
	content: "";
	display: block;
	left: 0;
	top: 0;
	width: 100%;
	text-align: center;
}
.placeholder::before {
	background: #ccc none repeat scroll 0 0;
	/* box-shadow: inset 0 1px 10px #bbb; */
	border-radius: 0px;
	height: 208px;
}
.placeholder::after {
	font-size: 49px;
	opacity: 0.25;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	font-family: "FontAwesome";
}
.placeholder.image::after {
	content: "\f03e";
}
.card-location p{
	display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 4.8em;
    line-height: 1.3em;
}