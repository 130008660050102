@import "variables";
@import "mixins";

img {
  vertical-align: top;
  max-width: 100%;
  height: auto;
}

.field-wrapper {
  margin: 0 0 16px;

  &.error {
    label {
      color: $error-color;
    }

    input, textarea {
      border-color: $error-color;
      color: $error-color;
      @include placeholder {
        color: $error-color;
      }
    }
  }
}

form,
fieldset,
button {
  margin: 0;
  padding: 0;
  border: none;
}

button, input:not([type="checkbox"]), textarea {
  -webkit-appearance: none;
}

input {
  box-sizing: border-box;
  border-radius: 0;
  font: 16px $primary-font-family;
  font-weight: 400;

  &[type=text],
  &[type=tel],
  &[type=email],
  &[type=password] {
    display: block;
    color: $darker-grey;
    height: 36px;
    width: 100%;
    padding: 0 21px;
    line-height: 100%;
    border: 1px solid $light-grey;
    letter-spacing: 0.8px;
  }

  @include placeholder {
    color: $darker-grey;
    opacity: 1;
  }

  &:focus {
    @include placeholder {
      opacity: 0.5;
    }
    outline: $dark-color auto 1px;
  }
}

textarea {
  font: 16px $primary-font-family;
  color: $darker-grey;
  width: 100%;
  padding: 10px 15px;
  height: 163px;
  border: 1px solid $light-grey;
  box-sizing: border-box;
  resize: none;
  border-radius: 0;

  @include placeholder {
    color: $darker-grey;
    opacity: 1;
  }
}

button {
  font-family: $primary-font-family;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  outline: none;
  border: none;
}

.error {
  &-message {
    display: none;
    color: $error-color;
    margin: 0 0 26px;
  }

  &-form &-message {
    display: block;
  }

  &-fields {
    display: block;
  }
}

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font: $font-size $primary-font-family;
  text-size-adjust: 100%;
  background-color: $body-color;
  padding-bottom: 0;
  overflow-x: hidden;
}

#main {
  margin: 74px 0 0;
}

a {
  color: inherit;
  text-decoration: none;
}

label {
  display: inline-block;
  font-size: 13px;
}

h1 {
  font-size: 30px;
}

.header-container {
  max-width: 1366px;
  margin: 0 auto;
}

.content-container {
  max-width: 1114px;
  margin: 0 auto;
}

.loading-block {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, .5);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.is-loading .loading-block {
  display: flex;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.main-section {
  z-index: 11;
}

p > a {
  color: $default-color;
  text-decoration: underline;

  &:hover {
    color: initial;
  }
}

.dropdown {
  position: relative;
  z-index: 3;

  &.show {
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.24) !important;

    .dropdown-content {
      display: block;
      box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.24) !important;
    }

    .dropdown-arrow {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  &-content {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: $light-color;
    box-sizing: border-box;
    z-index: -1;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }

  &-arrow {
    display: block;
    width: 16px;
    height: 9px;
    position: absolute;
    left: 20px;
    background: {
      image: url('../images/svg/black-arrow.svg');
      repeat: no-repeat;
      size: contain;
    }
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
}

.popup-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 105;
  background: rgba(2, 2, 2, 0.88);
  padding: 9px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  display: none;
}

.close-popup {
  font-size: 0;
  width: 15px;
  height: 15px;
  background: url("../images/svg/close-icon.svg") no-repeat;
  background-size: contain;
  display: block;
  margin: 0 auto 16px 0;
  cursor: pointer;
 
  &:hover {
    opacity: 0.3;
  }
}