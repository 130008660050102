@import "../variables";

.faq {
  padding: 49px 15px 45px;

  h1 {
    letter-spacing: 0.48px;
    padding-bottom: 27px;
    position: relative;
    margin: 0 0 32px;

    &::after {
      content: '';
      width: 37px;
      height: 1px;
      background-color: $dark-color;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  &__list {
    max-width: 657px;

    &__item {
      padding: 0 0 45px;

      &:last-child {
        padding-bottom: 0;
      }

      h2 {
        font-size: 22px;
        margin: 0 0 23px;
      }

      &:not(:last-child) {
        border-bottom: 1px solid $light-grey;
        margin: 0 0 43px;
      }
    }
  }

  &__item {
    position: relative;
    line-height: 1.6;

    &:not(:last-child) {
      margin-bottom: 14px;
      padding-bottom: 12px;

      &::after {
        content: "";
      }
    }

    &::after {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 78px;
      height: 1px;
      background-color: $blue-color;
    }

    h3, h4 {
      font-size: 16px;
      margin: 0;
    }

    p {
      font-size: 14px;
      line-height: 1.8;
      margin: 0;
    }
  }
}

