.loading-block.visible {
    display: flex !important;
}

.loading-block {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255,255,255,.5);
    display: none !important;
    justify-content: center;
    align-items: center;
    z-index: 100;
}