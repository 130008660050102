.MuiFormLabel-root{
    left: unset !important;
    right: 30px;
}

.MuiInputBase-root input[type="text"]::placeholder{
    text-align: right !important;
}

.MuiDialogContent-root{
    padding-top: 15px !important;
}

.main-account .MuiDialog-container h2{
    margin-bottom: 25px;
}

.MuiDialogActions-root{
    padding: 20px 24px !important;
}

.main-account .MuiOutlinedInput-root,
.MuiPaper-root{
    border-radius: 0 !important;
}

.main-account .date_picker{
    margin-bottom: 20px !important;
    width: calc(100% + 5px);
}

.time_picker{
    width: 50%;
}

.main-account .MuiInputBase-root input[type="text"],
.MuiInputBase-root input[type="tel"]{
    border: unset !important;
    height: 1.4375em !important; 
    padding: 16.5px 14px !important;
}

.date_picker .MuiInputBase-root fieldset, .time_picker > .MuiInputBase-root fieldset{
    text-align: right;
    right: 0;
}

.legend{
    padding: 0 5px;
    font-size: 0.95em;
}

.main-account .MuiDialog-root .MuiPaper-root{
    padding: 5px;
    border-radius: 0;
}

.MuiBox-root .css-w4z10b-MuiStack-root{
    margin-bottom: 15px;
    margin-top: -30px;
}

.main-account .MuiFormControl-root{
    margin-right: -8px !important;

}

.reports-info > div.MuiFormControl-root fieldset {
    text-align: unset;
}

.ml-5{
    margin-left: 14px !important;
}

span.mobile-report-preview{
    padding: 0;
    margin: 0;
}

span.mobile-report-preview span:not(:last-of-type){
    padding: 0 0 0 10px;
    position: relative;
}

span.mobile-report-preview span:not(:last-of-type)::after {
    content: "";
    width: 1px;
    height: 110%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: #e6e6e6;
}

div.mobile-report-preview{
    display: none;
}

.MuiPaper-root.MuiAccordion-root{
    box-shadow: none !important;
    background-color: transparent !important;
}

.MuiPaper-root::before{
    display: none;
}

.MuiButtonBase-root.MuiAccordionSummary-gutters{
    background-color: #fff !important;
    margin-bottom: 15px !important;
}

.MuiCollapse-root{
    background-color: #fff !important;
}

.MuiPaper-elevation.Mui-expanded{
    margin: 0 0 15px 0 !important;
}

.Mui-expanded{
    min-height: 21px !important;
}

.MuiAccordionSummary-content.Mui-expanded{
    margin: 12px 0 !important;
}

.MuiAccordionSummary-content{
    align-items: center;
}

.MuiDialog-container .MuiDialog-paper{
    margin: 0 15px;
}

.MuiTablePagination-actions{
    direction: ltr;
}

p.Mui-error{
    text-align: right;
    font-size: 14px;
}

.MuiDialog-container legend>span{
    padding-left: 22px !important;
}

@media screen and (min-width: 767px){
    .main-account .MuiDialog-root .MuiPaper-root{
        padding: 50px;
    }

    .time_picker{
        width: unset;
    }

    .date_picker{
        margin-bottom: 0;
        width: unset;
    }

    .ml-5{
        margin-left: 20px !important;
    }

    .MuiDialog-container .MuiDialog-paper{
        margin: 32px;
    }
}