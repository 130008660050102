@import "../variables";

.login {
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(24, 24, 24, 0.91);
  padding: 15px 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  &__popup {
    max-width: 824px;
    overflow-y: auto;
  }

  &__info {
    background: $light-color;
    padding: 62px 44px 98px;
    box-sizing: border-box;
    display: flex;
    gap: 88px;
    &.with-two-fields {
      padding-bottom: 27px;
    }

    h2 {
      font-size: 20px;
      line-height: 1.24;
      margin: 0 0 20px;
    }

    p {
      font-size: $font-size;
      line-height: 1.643;
      margin: 0 0 22px;
    }
  }

  &__text-info, &__form-info {
    flex-basis: calc(50% - 44px);
  }

  &__text-info p:last-child {
    margin-bottom: 0;
  }

  &__form {
    &-title p {
      margin: 0 0 14px;
    }
    label {
      margin: 0 0 3px;
    }
  }

  &__bottom-block {
    background: $body-color;
    padding: 21px 46px;
    display: flex;
    align-items: center;
    gap: 20px;
    box-sizing: border-box;
  }

  &__client-logo  {
    padding: 0 0 0 19px;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 141%;
      width: 1px;
      background: #dedede;
    }
  }

  .btn {
    margin: 29px auto 0 0;
  }
}

