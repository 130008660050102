@import "../variables";

.record-form {
  &__section {
    padding: 20px 15px 37px;

    .thanks, .thanks__content {
      padding: 0;
    }
  }

  &__wrapper {
    background: $light-color;
    padding: 56px 72px 61px;
  }

  &__form {
    max-width: 400px;
    margin: 0 auto;

    &.record-1__active [type="submit"] {
      display: flex;
    }

    &.record-2__active {
      .record-2 {
        display: block;
      }

      [type="submit"] {
        display: flex;
      }
    }

    &.error-form .record-2 {
      label, p {
        color: $error-color;
      }

      input {
        border-color: $error-color;
      }

      .btns {
        box-shadow: 0 0 0 1px $error-color;
        &::after {
          background: $error-color;
        }
      }

      .dropdown-arrow {
        background-image: url("../images/svg/red-arrow.svg");
      }
    }


    .title {
      margin: 0 0 49px;
    }

    h4 {
      font-size: 18px;
      text-align: center;
      margin: 0;
    }

    p {
      font-size: 18px;
      text-align: center;
      margin: 0 0 13px;
    }

    .btns {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 226px;
      border-radius: 18px;
      overflow: hidden;
      margin: 0 auto;
      position: relative;
      box-shadow: 0 0 0 1px #858585;

      &::after {
        content: "";
        width: 1px;
        height: 100%;
        background: #858585;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      input {
        display: none;

        &:checked + label {
          background-color: $default-color;
        }
      }

      label {
        font-size: 18px;
        text-align: center;
        flex-basis: 50%;
        padding: 7px;
        cursor: pointer;
      }
    }

    .record-2 {
      display: none;
      margin: 49px 0 0;

      .btns {
        margin-bottom: 49px;
      }
    }

    .dropdown {
      max-width: 226px;
      margin: 0 auto;

      &.show, &.show .dropdown-content {
        box-shadow: none !important;
      }

      &-input {
        pointer-events: none;
      }

      &-content {
        overflow: hidden;
        border: 1px solid #d8d8d8;
        border-top: 0;

        ul {
          max-height: 205px;
          overflow-y: auto;
        }
      }

      &-arrow {
        left: 9px;
      }

      input {
        height: 30px;
        padding-right: 9px;
        color: #000;
      }
    }

    .participant-count {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;

      label {
        font-size: 18px;
      }

      input {
        font-size: 18px;
        text-align: center;
        width: 62px;
        height: 30px;
        border: 1px solid #d8d8d8;
        -moz-appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }

    .who-add {
      margin: 42px 0 0;
    }

    [type="submit"] {
      display: none;
      font-size: 18px;
      margin: 49px auto 0;
      width: 226px;
    }

    .error-message {
      font-size: 16px;
      margin: 9px 0 0;
    }
  }
}

.project-info {
  display: flex;
  gap: 30px;
  margin: 0 0 10px;

  &__image {
    flex: 0 0 268px;

    .img-holder {
      position: relative;
    }
  }

  &__title {
    flex-grow: 1;
    margin: 0 0 38px;

    h1 {
      margin: 0 0 15px;
    }

    h2 {
      margin: 0 0 10px;
    }

    > h3 {
      font-size: 18px;
      font-weight: 400;
      margin: 0;

      &::after {
        content: "";
        display: block;
        background: #d0d0d0;
        width: 57px;
        height: 1px;
        margin: 40px 0 0;
      }
    }
  }

  &__description {
    margin: 0 0 26px;

    .subtitle {
      color: #666666;
    }

    p {
      margin: 0;

      &:not(.subtitle) {
        font-size: 18px;
        line-height: 1.4;
      }

      &:not(:last-child) {
        margin: 0 0 22px;
      }
    }
  }
}

.share {
  background: none;
  display: flex;
  align-items: center;
  gap: 7px;
  font-size: 16px;
  margin: 0 auto 32px 0;
  cursor: pointer;
}

.contacts {
  padding: 12px 0 17px;
  border-top: 2px solid $default-color;
  border-bottom: 2px solid $default-color;
  margin: 0 0 33px;

  h4 {
    font-size: 18px;
    margin: 0 0 18px;
  }

  &__info {
    display: flex;
    align-items: center;
    gap: 16px;

    .card-info-item {
      font-size: 18px;
      margin: 0;
      gap: 7px;

      &:not(:nth-child(3n)) {
        padding: 0 0 0 13px;
        position: relative;

        &::before {
          content: "";
          width: 1px;
          height: 100%;
          min-height: 31px;
          position: absolute;
          background: $default-color;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .working-mode svg {
      margin: 3px 0 0;
    }

    .time svg {
      margin: 2px 0 0;
    }

    .card-location {
      align-items: flex-start;
    }
  }
}

.checkbox-list {
  li {
    padding: 10px 9px;

    &:not(:last-child) {
      border-bottom: 1px solid #d8d8d8;
    }
  }
}

.custom-checkbox {
  font-size: 16px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  width: 100%;

  input {
    position: absolute;
    opacity: 0;
    text-indent: -9999px;

    &:checked + .checkmark {
      background: $default-color;

      &::after {
        display: block;
      }
    }
  }

  .checkmark {
    position: relative;
    display: block;
    height: 14px;
    width: 14px;
    border: 1px solid $default-color;

    &::after {
      content: "";
      position: absolute;
      display: none;
      left: 4px;
      top: -1px;
      width: 5px;
      height: 8px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  .count {
    margin: 0 auto 0 0;
    color: #858585;
  }
}
