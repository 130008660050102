table.event-info{
    width: 100%;
}

th{
    text-align: right;
}

.MuiDataGrid-row:hover{
    background-color: transparent !important;
}

.MuiDataGrid-cellContent,
.MuiDataGrid-columnHeader,
.MuiToolbar-root p,
.MuiList-root li{
    font: 14px "AlmoniDL",Arial,sans-serif !important;
}