@import "../variables";

.thanks {
  position: relative;
  padding: 97px 30px 207px;
  box-sizing: border-box;

  &__content {
    padding: 62px 50px 71px;
    background: $light-color;
    max-width: 885px;
    margin: 0 auto;
    box-sizing: border-box;
    text-align: center;

    h2 {
      font-size: 20px;
      font-weight: 400;
      line-height: 1.3;
      max-width: 590px;
      margin: 0 auto 16px;

      span {
        color: $default-color;
      }
    }

    .img-holder {
      max-width: 73px;
      margin: 0 auto 26px;
    }

    .btn {
      border: 1px solid $default-color;
      width: 100%;
      max-width: 252px;
      margin: 0 auto;

      &:hover {
        background: $default-color;
        color: $dark-color;
      }
    }
  }
}