.page-not-found {
    padding: 21px;
    margin-top: 73px;
    position: relative;
}

.page-not-found .content-outer {
    background-color: #fff;
    padding: 100px 20px 110px;
    position: relative;
    z-index: 1;
}

.page-not-found .page-not-found-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.page-not-found .page-not-found-img-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}