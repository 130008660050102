/* .cities-expanded-list div {
    background-color: #298cca;
    font-size: 14px;
    line-height: 27px;
    color: #fff;
    padding: 12px 22px;
    width: 183px;
    display: block;
    list-style: none;
    box-sizing: border-box;
    flex: 1 1 100px;
}

.cities-expanded-list{
    height: 300px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
} */

.who-add{
    display: flex;
    flex-direction: column;
    align-items: center;
}
input#participant-count{
    outline: none;
}

.who-add .MuiMenu-list li:not(:first-child){
    border-bottom: 1px solid gray;
}

.thanks__content h2{
    max-width: 523px;
}

.error-message.reg_error{
    display: block;
    text-align: center;
}

.spaceL{
    margin-left: 5px;
}
.select_menu_item{
    justify-content: space-between;
}
.cities-expanded-list{
    pointer-events: all;
    display: flex;
    visibility: visible;
    position: absolute;
}
.cities_hover_block{
    position: relative;
}
.share{
    justify-content: end;
    gap: 16px;
}
.share a{
    height: 16px;
}