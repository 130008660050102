@import "../variables";

.contact-us {
  background: #f4f4f4;
  padding: 32px 30px 78px;

  .content-container {
    max-width: 884px;
  }

  h1 {
    text-align: center;
    letter-spacing: 0.14px;
    margin: 0 0 38px;
  }
}

.contact-form {
  max-width: 481px;
  margin: 0 auto;
  font-size: 16px;

  div:nth-last-child(3) {
    margin-bottom: 9px;
  }

  &__wrapper {
    background: $light-color;
    padding: 45px 30px 48px;
  }

  &__title {
    margin: 0 0 38px;
  }

  .field-wrapper {
    margin: 0 0 52px;
  }

  label {
    font-size: 16px;
    margin: 0 0 4px;
  }

  .checkbox-wrapper {
    margin: 0 0 55px;

    label {
      margin: 0;
      padding: 0 23px 0 0;
      position: relative;
      cursor: pointer;

      &::before {
        content: "";
        position: absolute;
        top: 1px;
        right: 0;
        width: 14px;
        height: 14px;
        border: 1px solid $light-grey;
        background: url("../images/svg/check-icon.svg") no-repeat 50% 1px;
        background-size: 13px;
      }
    }

    input {
      display: none;

      &:checked + label::before {
        background-color: #0075ff;
        border-color: #0075ff;
      }
    }
  }
}