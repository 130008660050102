$primary-font-family: 'AlmoniDL', Arial, sans-serif;

$font-size: 14px;
$body-color: #F0F0F0;

$dark-color: #000;
$light-color: #fff;
$default-color: #298cca;
$hover-default-color: #52a7dc;
$error-color: #ff0505;
$light-grey: #e0e0e0;
$darker-grey: #858585;
$blue-color: #2a8cca;

